export const environment = {
  production: false,
  authority: 'https://login.microsoftonline.com/30f52344-4663-4c2e-bab3-61bf24ebbed8',
  redirectUri: 'https://sit.hmorder-orderhistory.hmgroup.com',
  Graph_Endpoint: 'https://graph.microsoft.com/v1.0/me',
  Graph_EndPoint_Photo: 'https://graph.microsoft.com/v1.0/me/photo/$value',
  orderHistoryQueryApiUrl: 'https://sit-deng-orderhistory-api.azurewebsites.net/api/',
  orderHistoryWebApiScope: 'api://9938320e-825f-48f9-a696-c6108ab382fe/Orders.Read',
  dengOrderReportApiUrl: 'https://sit-orderreport-api.azurewebsites.net/v1/orders/',
  dengOrderReportApiScope: 'https://sit-orderreport-api.az.hmgroup.com/Report.Read',
  clientId: '45864b12-5522-4fe5-9872-b7f9eac3b595'
};
